import React from 'react';
import { Button, Row } from "reactstrap";
import { confirmAlert } from 'react-confirm-alert';
import {useStoreActions} from "easy-peasy";

const CollectionItem = (props) => {
    const { deleteCollection, setSelectedCollection } = useStoreActions(action => ({
        deleteCollection: action.deleteCollection,
        setSelectedCollection: action.setSelectedCollection,

    }))
    const item = props.item
    const edit = () => {
        setSelectedCollection(item)
        // props.history.push(`/app/podcast_collection/edit/${item._id}`)
    }

    const deleteTagConfirm = () => {
        confirmAlert({
            title: 'Delete Podcast Collection',
            message: 'Are you sure to delete this podcast collection?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteCollection({
                        id: item?._id
                    })

                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const openPodcast = () => {
        props.history.push(`/app/podcasts/${item._id}`)
    }

    return (
        <>
            <div onClick={openPodcast} className="card card-hashtag">
                <p>ID: {item?.id}</p>
                <Row>
                    <div >
                        <Button className={'button-tag'} color="danger" onClick={deleteTagConfirm}> Delete </Button>
                        <Button className={'button-tag'} color={"primary"} onClick={() => edit()}>Edit</Button>
                    </div>
                </Row>
                <br />
                <div className="hashtag-info">
                    <span className="span-author">{item?.title}</span>
                    <span className="span-grey">{item?.description}</span>
                </div>
                <img src={item?.imageUrl} alt="thumbnail" className='hashtag-image' />
            </div>
        </>
    );
}


export default CollectionItem;
